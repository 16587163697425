<template>
  <div>
    <AppTable
      :rows="rows"
      :columns="columns"
      :is-loading="requestInProgress"
      :pagination="{
        limit: buySell.limit,
        count: buySell.count,
        page: buySell.page,
      }"
      @change-limit="changePageLimit"
      @change-page="changePage"
    >
      <template #prependFilters>
        <b-row>
          <b-col md="4" xl="4" class="mb-1">
            <AssetFilter v-model="selectAsset" label="Crypto" placeholder="Select crypto" @change="changeAsset" />
          </b-col>

          <b-col md="4" xl="4" class="mb-1">
            <b-form-group label="Start date" label-for="datepicker-dateFrom">
              <b-form-datepicker
                id="datepicker-dateFrom"
                v-model="dateFrom"
                placeholder="Choose a min date"
                local="en"
                today-button
                reset-button
                close-button
              />
            </b-form-group>
          </b-col>

          <b-col md="4" xl="4" class="mb-1">
            <b-form-group label="End date" label-for="datepicker-dateTo">
              <b-form-datepicker
                id="datepicker-dateTo"
                v-model="dateTo"
                placeholder="Choose a max date"
                local="en"
                today-button
                reset-button
                close-button
              />
            </b-form-group>
          </b-col>

          <b-col md="4" xl="3" class="mb-1">
            <b-form-group>
              <label class="mr-1">Type</label>
              <b-form-select v-model="activityType" :options="typeOptions" @change="changeType" />
            </b-form-group>
          </b-col>

          <b-col md="4" xl="3" class="mb-1">
            <b-form-group>
              <label class="mr-1">Status</label>
              <b-form-select v-model="activityStatus" :options="statusOptions" @change="changeStatus" />
            </b-form-group>
          </b-col>

          <b-col md="11" xl="3" class="mb-1">
            <b-form-group>
              <label class="mr-1">Search</label>
              <b-form-input v-model="searchTerm" placeholder="search by username / email" type="text" />
            </b-form-group>
          </b-col>

          <b-col md="1" xl="1" class="mb-1 mt-auto pb-1">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              :disabled="requestInProgress"
              class="mt-auto"
              type="button"
              variant="primary"
              @click="search"
            >
              <feather-icon icon="SearchIcon" size="18" />
            </b-button>
          </b-col>
        </b-row>
      </template>

      <template #default="{ column, row, formattedRow} = {}">
        <span v-if="column.field === 'status'">
          <b-badge :variant="row.status.theme" class="text-white" pill>
            {{ row.status.text }}
          </b-badge>
        </span>

        <span v-else-if="column.field === 'type'">
          <span :variant="row.type.theme" class="text-white" pill>
            {{ row.type.text }}
          </span>
        </span>

        <!-- Column: Action -->
        <span v-else-if="column.field === 'details'" class="d-flex">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            class="mt-auto text-white ml-1"
            type="button"
            variant="info"
            @click="toDetail(row)"
          >
            Details
          </b-button>
        </span>

        <!-- default render cell -->
        <span v-else class="flex-center-align">
          {{ formattedRow[column.field] }}
        </span>
      </template>
    </AppTable>
  </div>
</template>

<script>
import { BButton, BRow, BCol, BFormGroup, BFormInput, BFormSelect, BFormDatepicker, BBadge } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { mapActions, mapGetters } from 'vuex'

import waitRequest from '@/mixins/waitRequest'
import validationError from '@/mixins/validationError'
import statusRender from '@/mixins/statusRender'
import columns from './config/tableConfig'
import statusThemeCinfig from './config/statusThemeCinfig'
import AppTable from '@/components/AppTable.vue'
import { formattedDate } from '@/tools/util'
import { transactionBuySellStatus, transactionBuySellTypes } from '@/views/apps/buy-sell/config/transactionTypes'
import AssetFilter from '@/components/containers/AssetFilter.vue'

export default {
  name: 'BuySellView',
  components: {
    AssetFilter,
    AppTable,
    BBadge,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormDatepicker,
    BFormSelect,
  },
  directives: {
    Ripple,
  },
  mixins: [waitRequest, validationError, statusRender],
  data() {
    return {
      columns,
      selectAsset: '',
      searchTerm: '',
      dateTo: '',
      dateFrom: '',
      activityStatus: '',
      typeOptions: [
        { value: '', text: '' },
        { value: transactionBuySellTypes.send_crypto, text: 'Send crypto' },
        { value: transactionBuySellTypes.receive_crypto, text: 'Receive crypto' },
      ],
      activityType: '',
      statusOptions: [
        { value: '', text: '' },
        { value: transactionBuySellStatus.wait_crypto_payment, text: 'Wait crypto payment' },
        { value: transactionBuySellStatus.wait_fiat_payment, text: 'Wait fiat payment' },
        { value: transactionBuySellStatus.pending_payout, text: 'Pending payout' },
        { value: transactionBuySellStatus.pending, text: 'Pending' },
        { value: transactionBuySellStatus.paid, text: 'Paid' },
        { value: transactionBuySellStatus.new, text: 'New' },
        { value: transactionBuySellStatus.error, text: 'Error' },
        { value: transactionBuySellStatus.reject, text: 'Reject' },
        { value: transactionBuySellStatus.crypto_is_sending, text: 'Crypto is sending' },
        { value: transactionBuySellStatus.success, text: 'Success' },
      ],
    }
  },

  computed: {
    ...mapGetters({
      buySell: 'pgTransactions/buySell',
    }),

    rows() {
      return this.transformBuySellData(this.buySell?.value || [])
    },
  },
  mounted() {
    this.initState()
  },

  methods: {
    formattedDate,
    ...mapActions({
      fetchBuySell: 'pgTransactions/fetchBuySell',
    }),

    initState() {
      const query = this.createQueryConfig()

      this.getByeSell(query)
      this.setStatusConfig(statusThemeCinfig)
    },

    changeAsset(asset) {
      const query = this.createQueryConfig({ coinId: asset?.id || null })

      return this.getByeSell(query)
    },

    search() {
      const query = this.createQueryConfig({ search: this.searchTerm, page: 1 })

      return this.getByeSell(query)
    },

    changePageLimit(pageLimit) {
      const query = this.createQueryConfig({ limit: pageLimit })

      return this.getByeSell(query)
    },

    changeStatus(status) {
      const query = this.createQueryConfig({ status })

      return this.getByeSell(query)
    },

    changeType(type) {
      const query = this.createQueryConfig({ type })

      return this.getByeSell(query)
    },

    changePage(pageNumber) {
      const query = this.createQueryConfig({ page: pageNumber })

      return this.getByeSell(query)
    },

    createQueryConfig(config) {
      return {
        page: this.buySell.page,
        limit: this.buySell.limit,

        coinId: this.selectAsset?.id || undefined,
        term: this.searchTerm || undefined,
        from: this.dateFrom || undefined,
        to: this.dateTo || undefined,
        type: this.activityType ?? undefined,
        status: this.activityStatus ?? undefined,

        ...config,
      }
    },

    getByeSell(query) {
      return this.waitRequest(() => this.fetchBuySell(query).catch(this.checkErrors))
    },

    toDetail(row) {
      this.$router.push(`/buy-sell/${row.id}`)
    },

    transformBuySellData(rows = []) {
      return rows.map(row => {
        const createdAt = row.createdAt ? this.formattedDate(row.createdAt) : ''
        const lastTry = row.lastTry ? this.formattedDate(row.lastTry) : ''

        const isSendCrypto = row.type === 'send_crypto'
        const type = {
          text: isSendCrypto ? 'Send crypto' : 'Receive crypto',
          theme: isSendCrypto ? 'light-primary' : 'light-success',
        }

        // const fee = `${row.feeAmount || ''} ${row.feeCoin?.symbol || ''}`

        const status = {
          theme: this.getStatusTheme(row),
          text: this.getStatusText(row),
        }

        const network = row.network ? `/ ${row.network.network}` : ''
        const soldAmount = `${row.paidAmount || ''}`
        const soldFiatCryptoCurrency = `${row.paidCoin?.name || ''} ${row.paidCurrency?.nameCurrency || ''} ${
          isSendCrypto ? network : ''
        }`

        const boughtAmount = `${row.receivedAmount || ''}`
        const boughtFiatCryptoCurrency = `${row.receivedCoin?.symbol || ''} ${row.receivedCurrency?.nameCurrency || ''} ${!isSendCrypto ? network : ''}`

        return {
          ...row,
          createdAt,
          status,
          type,
          soldAmount,
          soldFiatCryptoCurrency,
          boughtAmount,
          boughtFiatCryptoCurrency,
          lastTry,
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-sweetalert.scss';
</style>
